<template>
  <!-- <div id="app" style="min-width: 1530px; width: 100%"> -->
  <div id="app" style="min-width: 1260px">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style scoped lang="less"></style>
